import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_zh from './zh.json'
const resources = {
    zh:{
        translation:translation_zh
    }
}

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'zh',
    lng: 'zh',
    // debug: true,
    resources: resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;