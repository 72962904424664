import React from 'react'
import './style.scss'
export default function Loading() {
    return (
        <div id="loading-wrap" className="loading-wrap animated" style={{"display": "flex", "animationName": "gp-fadeOut"}}>
            <div className="loading-content">
                <svg t="1640924208329" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2263"><path d="M512 64c247.2 0 448 200.8 448 448h-64c0-212-172-384-384-384V64z m0 832c-212 0-384-172-384-384H64c0 247.2 200.8 448 448 448v-64z" p-id="2264" fill="#ffffff"></path>
                </svg>
            </div>
        </div>
    )
}
