import {lazy, Suspense} from 'react';
// 导入路由依赖
import { createBrowserRouter,RouterProvider } from "react-router-dom";
import Loading from '../component/Loading';
const Home=lazy(()=>import('../page/home'))
const News=lazy(()=>import('../page/news'))


// 使用useRoutes 创建

const router=createBrowserRouter(
    [
        {
            path:"/",
            element:<Suspense fallback={<Loading/>}>
                <Home/>
            </Suspense>
        },
        {
            path:"/news/:id",
            element:
            <Suspense fallback={<Loading/>}>
                <News/>
            </Suspense>
        }
    ]
)

export default  function RouterView() {
        // 创建路由
        return (
            <RouterProvider router={router} />
        )
    }
