import './App.css';
import './locales/i18n'
import GoTopShow from './component/GoTopShow';
import 'animate.css';
import 'suneditor/dist/css/suneditor.min.css';
import RouterView from './router';
function App() {
  return (
    <div className="App">
      <RouterView/>
      <GoTopShow></GoTopShow>
    </div>
  );
}

export default App;
