import React, { useRef, useEffect,useState } from 'react'
import './style.scss'
export default function GoTopShow() {
    const ref = useRef();  
    const [show,setShow]=useState(false)
    useEffect(() => {  
        const handleScroll = () => {  
          if (window.scrollY > window.innerHeight/4) {  
            setShow(true)
          }else{
            setShow(false)
          }
        };  
        window.addEventListener('scroll', handleScroll);  
        return () => {  
          window.removeEventListener('scroll', handleScroll);  
        };  
      }, []);  
      function goToTop(){
        window.scrollTo(0, 0)
      }
  return (
    <div onClick={goToTop} ref={ref} className={show?"ge-goTop goTopShow animate__animated animate__fadeIn":"ge-goTop goTopHide animate__animated animate__fadeOut"} >
        <svg t="1640684915339" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2778" data-spm-anchor-id="a313x.7781069.0.i0" width="35" height="35"><path d="M528.968975 162.41916c-4.493338-4.493338-10.600429-7.045463-16.969487-7.045463-6.370081 0-12.43317 2.552126-16.97051 7.045463L320.358797 337.024873c-9.335622 9.401114-9.335622 24.559349 0 33.938973 9.422603 9.358135 24.604374 9.358135 33.938973 0l157.700695-157.700695 157.699672 157.700695c4.668323 4.64581 10.818393 6.978948 17.013489 6.978948 6.064113 0 12.258185-2.333138 16.926508-6.978948 9.335622-9.379625 9.335622-24.537859 0-33.938973L528.968975 162.41916 528.968975 162.41916zM528.968975 162.41916" p-id="2779" fill="#ffffff"></path>
        <path d="M65.290517 606.881974l102.55984 0L167.850357 868.626303l47.89994 0L215.750297 606.881974l102.55984 0 0-47.942919L65.290517 558.939055 65.290517 606.881974zM65.290517 606.881974" p-id="2780" fill="#ffffff"></path><path d="M582.713017 558.939055l-127.033231 0c-15.311731 0-30.667463 5.866614-42.315758 17.537422-11.778254 11.735275-17.624403 27.047006-17.624403 42.380226l0 189.873442c0 15.312754 5.846148 30.645974 17.624403 42.35976 11.648294 11.691273 27.004027 17.537422 42.315758 17.537422l127.033231 0c15.22475 0 30.624485-5.846148 42.35976-17.537422 11.691273-11.712763 17.536398-27.047006 17.536398-42.35976L642.609175 618.856703c0-15.33322-5.845125-30.644951-17.536398-42.380226C613.337501 564.806693 597.937766 558.939055 582.713017 558.939055L582.713017 558.939055zM594.666256 808.730145c0 2.26867-0.610914 5.54018-3.533476 8.463766-2.835581 2.87856-6.195096 3.489474-8.419763 3.489474l-127.033231 0c-2.26867 0-5.584182-0.610914-8.463766-3.489474-2.87856-2.922563-3.489474-6.195096-3.489474-8.463766L443.726545 618.856703c0-2.26867 0.610914-5.54018 3.489474-8.463766 2.87856-2.921539 6.195096-3.511987 8.463766-3.511987l127.033231 0c2.224668 0 5.584182 0.589424 8.419763 3.511987 2.922563 2.922563 3.533476 6.195096 3.533476 8.463766L594.666256 808.730145 594.666256 808.730145zM594.666256 808.730145" p-id="2781" fill="#ffffff"></path>
        <path d="M941.21504 576.476477c-11.779278-11.669784-27.091008-17.537422-42.402739-17.537422L734.481605 558.939055 734.481605 868.626303l47.898917 0L782.380522 732.802872l116.432803 0c15.311731 0 30.623461-5.845125 42.402739-17.557888 11.647271-11.691273 17.493419-27.025517 17.493419-42.337247l0-54.05001C958.70846 603.523483 952.862311 588.211752 941.21504 576.476477L941.21504 576.476477zM910.765541 672.906713c0 2.224668-0.610914 5.54018-3.490497 8.463766-2.921539 2.87856-6.238075 3.489474-8.462742 3.489474L782.380522 684.859953l0-77.977979 116.432803 0c2.224668 0 5.54018 0.589424 8.462742 3.511987 2.879584 2.922563 3.490497 6.195096 3.490497 8.463766L910.766564 672.906713 910.765541 672.906713zM910.765541 672.906713" p-id="2782" fill="#ffffff"></path>
        </svg>
        </div>
  )
}
